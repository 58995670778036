@import "theme/variables";

h1#lead {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

// h1#second::before {
//   content: "\00a0Jide.";
//   text-transform: uppercase;
//   opacity: 0;
//   animation: animate infinite 10s;
// }
span#second::after {
  content: "\00a0Jide.";
  text-transform: uppercase;
  animation: animate infinite 10s;
}
@keyframes animate {
  0%,15% {
      content: "\00a0Jide.";
      opacity: 1;
  }
  19% {
      content: "\00a0Jide.";
      opacity: 0;
  }

  20% {
      content: "\00a0記得";
      opacity: 0;
  }
  25%,40% {
      content: "\00a0記得";
      opacity: 1;
  }
  44% {
    content: "\00a0記得";
    opacity: 0;
  }

  45% {
      content: "\00a0记得";
      opacity: 0;
  }
  50%,65% {
      content: "\00a0记得";
      opacity: 1;
  }
  69% {
      content: "\00a0记得";
      opacity: 0;
  }

  70% {
      content: "\00a0remember";
      opacity: 0;
  }
  75%,90% {
      content: "\00a0remember";
      opacity: 1;
  }
  94% {
      content: "\00a0remember";
      opacity: 0;
  }

  95% {
      content: "\00a0Jide.";
      opacity: 0;
  }
  100% {
      content: "\00a0Jide.";
      opacity: 1;
  }
}

strong {
  font-weight: $font-weight-black;
}

:root, :root.light, :root .light {
  --jj-gradient: #{linear-gradient(-45deg, #00897B 25%, #80CBC4)};
}

.bg-jj-flat {
    background-color: $jj-dark;
}

.bg-jj {
    background-image: var(--jj-gradient);
}


// lazy way to make all links under our FAQ the primary color
#FAQ a {
  @extend .link-primary;
}

// .bg-taipei {
//     background-image: var(--jj-gradient);
// }

$taipeiSmPath: "/assets/img/connect/taipei-sunset-sm.jpg";
$taipeiPath: "/assets/img/connect/taipei-sunset.jpg";
$taipeiXxlPath: "/assets/img/connect/taipei-sunset-xxl.jpg";
.hero-bg-taipei {
  @include media-breakpoint-up(sm) {
    background-image: url("#{$taipeiPath}"); /* The avg size image used */
  }
  @include media-breakpoint-up(xxl) {
    background-image: url("#{$taipeiXxlPath}");
    // height: 500px; /* You must set a specified height */
    min-height: 448px;
  }
  background-image: url("#{$taipeiSmPath}"); /* The image used for mobile widths */
  background-color: $jj-dark; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

#substack-frame {
  background-color: transparent;
  border: 1px solid #00000011;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;  // mobile needs more space since it's not wide enough
  @include media-breakpoint-up(md) {
    // height: 144px;  // hide logo and description but still has room for the error text or post-submit UI.
    // height: 244px;  // just shows the description, no logo
    // height: 290px;  // just shows the description and logo
    height: 244px;
  }
  @include media-breakpoint-up(420) {
    max-width: 338px;
  }
  // allow for wider input box
  @include media-breakpoint-up(md) {
    min-width: 352px;
  }
}

// Overlay the background with this gradient fade to black to match the footer.
.hero-bg-taipei::before {
  content:' ';
  position:absolute;
  left:0;
  right:0;
  top:0;
  height:1.5em;
  background:linear-gradient(
    to top,
    rgba(0,0,0,0),
    rgba($jj-dark, .99),
  );
  // rgba(0, 137, 123, .95) = 00897B
  // #004D40
  z-index:1;
}
.hero-bg-taipei::after {
  content:' ';
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  height:1em;
  background:linear-gradient(
    to bottom,
    rgba(0,0,0,0),
    rgba(0,0,0,0.95)
  );
  //#004D40
  z-index:1;
}

// Useful for overriding a default Section mixin, as done in Connect
.nopad {
  padding-top: 0px;
  padding-bottom: 0px;
}

// Used in the Connect section
.text-shadow {
  text-shadow: black 0.05em 0.05em 0.2em;
}

// Re: parent must be { position: relative }
.overlay {
  // Center any child content we want to use as part of the overlay
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;

  // Fill our parent with this tinted layer.
  position: absolute;
  border: none;
  margin: 0;
  height: 100%;
  width: 100%;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.33);
  color: whitesmoke;
  text-shadow: black 0.1em 0.1em 0.1em;
  border-radius: 8px;
}

#cb-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-radius: 0;
  display: none;
  background-color: $gray-1000;
}

// Match the colors set in our privacy_block.html
#privacy-page {
  font-family: Arial !important;
  color: #595959;
}
#privacy-page a {
  color: #3030F1 !important;
}